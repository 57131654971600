import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../../components/layout";

class SenseiKenOgawa extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Sensei Ken Ogawa</h1>
                <p>Sensei Ogawa is regarded by Sensei Morio Higaonna as his strongest karate student ever, I had the
                    privilege of meeting him during my training years at the Yoyogi dojo and write about it in my book
                    "Yoyogi Dojo '74".</p>

                <p>Here is part of the chapter dedicated to the late Sensei Ken Ogawa who truly is the legend of Yoyogi
                    dojo. He is also mentioned Antonio Bustillo's book "Steady Training".</p>


                <h2><strong>The Legend of Yoyogi Dojo</strong> - Chapter 25 (excerpt of Sensei Ravey's book)</h2>
                <p>10 years had past since that first Karate class at Edwin Swale school, I started climbing the ladder
                    of "Karate Proficiency" and was no longer feeling like a beginner until the day I met Sensei Ken
                    Ogawa. I first came across his name written on a hammer used for knocking in nails that had lifted
                    on the wooden Yoyogi dojo floor.</p>

                <p>"Who is Ken Ogawa?" I asked and then was told stories, such as Terry O'Neil (the British team
                    captain) had brought some Shotokan lads to Japan to train at the JKA (Shotokan HQ). Terry was a
                    friend of Sensei Higaonnas and brought the guys down to Yoyogi dojo to meet him. As they entered the
                    dojo only one man was training by himself, Ken Ogawa. They saw him do 100 press ups, then 50 on each
                    arm and walk out of the dojo into the changing room on his knuckles. The funny side to the story is
                    that this was the first Goju Ryu practitioner the English Shotokan team had seen and for a moment
                    thought all Goju men were of this standard.</p>

                <p>I heard that while training for the all Tokyo championships one year, Ken Ogawa had bunny hopped up
                    and down the Tokyo tower steps (590 steps) after he ran there from the Yoyogi dojo (about 4-5 km).
                    He had viciously fought some South African Karate-ka one morning and left them needing medical
                    attention because he thought they had shamed Sensei Higaonna, apparently the night before they all
                    got pissed in a gay bar, caused a bit of a ruckus in which the police got involved who in turn
                    called Sensei Higaonna. He had to bail them out in the middle of the night. This did not go down
                    well with Ken Ogawa and took it upon himself to "punish them" the following morning.</p>

                <p>I listened to these stories but thought probably that they had been exaggerated over time as stories
                    tend to, and nobody can be that good, can they? Then I met him...........</p>
            </Layout>);
    }
}

export default SenseiKenOgawa;
